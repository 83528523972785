.alert {
    border-radius: 6px;
    color: white;
    margin-bottom: 15px;
    padding: 20px;
}

.alert.error {
    background-color: red;
}

.alert.info {
    background-color: teal;
}

.alert .close {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 22px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 15px;
    transition: 0.3s;
}

body {
    max-width: 1200px;
}

label {
    font-weight: bold;
    margin-right: 20px;
}