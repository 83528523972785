.alert {
  color: #fff;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 20px;
}

.alert.error {
  background-color: red;
}

.alert.info {
  background-color: teal;
}

.alert .close {
  color: #fff;
  cursor: pointer;
  float: right;
  margin-left: 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 20px;
  transition: all .3s;
}

body {
  max-width: 1200px;
}

label {
  margin-right: 20px;
  font-weight: bold;
}

/*# sourceMappingURL=index.de40c17c.css.map */
